import React from 'react';
import styled from 'styled-components';

import { Color, P } from '@src/components/atoms';
import LinkWrapper from '@src/components/molecules/link';
import buttonStyle from '@src/style/button';
import { withRouter } from 'next/router';

function UnAuthNavigationContainer({ router }) {
	
	return (
		<Button
			href={`/login?redirectTo=${router.asPath}`}
			bgColor={Color.BACKGROUND_DEEP_DARK_GREY}
			hColor="black"
		>
			<P level={1} color="black" style={{fontWeight:router.pathname.includes('/login') ? 800 : 600, color:router.pathname.includes('/login') ? '#1c1c1c' : '#838383'}}>
				로그인
			</P>
		</Button>
	);
}

export default withRouter(UnAuthNavigationContainer);

const Button = styled(LinkWrapper)<{ bgColor: string; hColor: string }>`
	${buttonStyle.Default}
	padding: 0 0.1rem;
	height: 0.4rem;
`;
